
import DetailCard   from '@/components/DetailCard.vue';
import { defineComponent} from 'vue';
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'

export default defineComponent({
 name: 'App',
 components: {
   DetailCard,
   Loading,
   Error
 },
 data(){
    return{
    paramId : this.$route.params.id as string
 }},
 created: function(){
   this.$store.dispatch('getCountry', {"urlParam" : this.paramId});
 },
 watch:{
    $route (to){
      if(to.params.id){
       this.$store.dispatch('getCountry', {"urlParam" : to.params.id});
      }
    }
 },
computed:{
  loading(){
    return this.$store.state.loading;
  },
   countryDetail(){
     return this.$store.state.countryData[0];
   }
 }
})

